import axios from "axios";

export class Abstract {
    constructor(controller, session) {
        this.controller = controller;
        this.id = null;
        if (session) 
        {
            this.user = session.user;
            this.id_company = session.company;
            this.id_branch = session.branch;
        }
        else 
        {
            this.user = null;
            this.id_company = null;
            this.id_branch = null;
        }
        this.created = null;
        this.created_by = null;
        this.modified = null;
        this.modified_by = null;
    }
    async all() {
        let response = await axios.get(this.controller, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
    async data(params) {
        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        
        let response = await axios.get(this.controller + "/data" + query, {
            headers: {
                user: this.user
            }
        });
        return response.data;
    }
    async retrieve() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Ingrese un id para encontrar el registro"
        let response = await axios.get(this.controller + "/" + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
    async save() {
        this.strUsuarioAlta = this.usuario;
        let response = await axios.post(this.controller, this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
    async update() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro para actualizar"
        this.strUsuarioMod = this.usuario;
        let response = await axios.put(this.controller + "/" + this.id, this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
    async delete() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro para eliminar";
        await axios.delete(this.controller + "/" + this.id);
    }
}