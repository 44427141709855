import {
    Abstract
} from './Abstract';

import axios from "axios";

export class Invoice extends Abstract {
    constructor(session) {
        super('invoice/InvoiceManager', session);
        this.version = '4.0';
        this.series = null;
        this.id_invoice = null;
        this.date = new Date();
        this.payment_form = null;
        this.payment_coditions = null;
        this.subtotal = 0.00;
        this.discount = 0.00;
        this.currency = 'MXN';
        this.exchange_rate = 1.00;
        this.voucher_type = "I";
        this.export = "01";
        this.payment_method = "PUE";
        this.expedition_place = null;
        this.confirmation = null;
        this.global_information_periodicity = null;
        this.global_information_months = null;
        this.global_information_anio = null;
        this.issuing_rfc = null;
        this.issuing_name = null;
        this.issuing_tax_regime = null;
        this.issuing_attribute = null;
        this.receiver_rfc = null;
        this.receiver_name = null;
        this.receiver_postal_code = null;
        this.receiver_tax_residence = null;
        this.receiver_num_reg_id_trib = null;
        this.receiver_tax_regime = null;
        this.receiver_invoice_use = null;
        this.taxes_total_detained = 0.00;
        this.taxes_total_transferred = 0.00;
        this.taxes_total = 0.00;
        this.total = 0.00;
        this.invoice_concepts = [];
        this.timbrar = true;
        this.XMLfilename = null;
        this.status = null;
        this.is_certificated = false;
        this.uuid = null;
        this.certification_date = null;
        this.receiver_email = null;
        this.original_chain = null;
        this.sat_certificate = null;
        this.sat_stamp = null;
        this.id_customer = null;
        this.id_consignment_note = null;
        this.amount_paid = null;
        this.id_order = null;
    }

    async payment_invoices(params) {
        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        
        let response = await axios.get(this.controller + "/payment_invoices" + query, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async update() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro para actualizar"
        this.strUsuarioMod = this.usuario;
        let response = await axios.put(this.controller + "/" + this.id, this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async generate() {
        let response = await axios.post(this.controller + '/generate/40', this, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async regenerate() {
        console.log(this);
        let response = await axios.put(this.controller + '/regenerate/40/' + this.id, this, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async downloadXML() {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para descargar el XML"
        let response = await axios({
                method: "get",
                url: this.controller  + "/xml/download/" + this.id,
                responseType: "blob"
            });
        var content = response.data;
        var blob = new Blob([content]);
        //var fileName = "ANUMA.xml";
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = this.xmLfilename;
        link.click();
        URL.revokeObjectURL(link.href);
    }

    async downloadAcuseXML() {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para descargar el XML"
        let response = await axios({
                method: "get",
                url: this.controller  + "/xml/download/acuse/" + this.id,
                responseType: "blob"
            });
        var content = response.data;
        var blob = new Blob([content]);
        //var fileName = "ANUMA.xml";
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "ACUSE_" + this.xmLfilename;
        link.click();
        URL.revokeObjectURL(link.href);
    }

    async downloadAcusePDF() {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para descargar el XML"
        let response = await axios({
                method: "get",
                url: this.controller  + "/pdf/download/acuse/" + this.id,
                responseType: "blob"
            });
        var content = response.data;
        var blob = new Blob([content]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "ACUSE_" + this.xmLfilename.split('.')[0] + ".pdf";
        link.click();
        URL.revokeObjectURL(link.href);
    }


    async downloadPDF() {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para descargar el PDF"
        let response = await axios({
                method: "get",
                url: this.controller  + "/pdf/download/" + this.id,
                responseType: "blob"
            });
        var content = response.data;
        var blob = new Blob([content]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = this.xmLfilename.split('.')[0] + ".pdf";
        link.click();
        URL.revokeObjectURL(link.href);
    }

    async concepts() {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para recibir sus conceptos"
        let response = await axios.get(this.controller + '/concepts/' + this.id, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async order() {
        if (this.id_order == null || this.id_order == 0 || this.id_order == "") 
            throw "Seleccione un pedido para recibir la información."
        let response = await axios.get(this.controller + '/order/' + this.id_order, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async order_items() {
        console.log("aqui")
        if (this.id_order == null || this.id_order == 0 || this.id_order == "") 
            throw "Seleccione un pedido para recibir la información."
        let response = await axios.get(this.controller + '/order_items/' + this.id_order, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async list(params) { 
        console.log("Parametros: " + JSON.stringify(params))
        let response = await axios.post(this.controller + '/data', params, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }
    
    async prodserv(params) {
        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        
        let response = await axios.get(this.controller + "/prodserv" + query, {
            headers: {
                user: this.user
            }
        });
        return response.data;
    }

    async certificate() {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para timbrar"
        let response = await axios.post(this.controller + '/timbrar/40/' + this.id, null, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async cancel(cancelation_reason, uuid) {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para cancelar"
        let response = await axios.post(this.controller + '/cancelar/40/' + this.id + '/' + cancelation_reason, {
            uuid: uuid
        }, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }
}
